<template>
  <div class="page_news_content">
    <div class="bgblue">
      <div class="page_body_con">
        <div class="toptitle">
          <div class="margin-tb-15 flex-space-center">
            <div class="page_location">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item>当前位置:</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/' }">
                  <i class="el-icon-s-home"></i> 首页</el-breadcrumb-item
                >
                <el-breadcrumb-item :to="{ path: '/news' }">新闻中心</el-breadcrumb-item>
                <el-breadcrumb-item>新闻详情</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
          </div>
          <div class="title ta-l">
            <h6>{{ info.title }}</h6>
            <p class="descspan">发布日期：{{ info.create_time }} 发布者：中集智本</p>
          </div>
        </div>
      </div>
    </div>
    <div class="page_body_con">
      <el-row class="page_content">
        <el-col :span="20" class="white_bg margin-rt-15 moment-row">
          <p class="info" v-html="info.content">
            <!-- 感谢您长期以来对我司的关注、陪伴和见证了我们的成长，小智给各位“衣食父母”汇报一下近期取得的成绩，截止发搞时，我们服务客户量已过10000，遍及全国各地。为了给客户提供性价比更高的产品，我们从产品的研发、原料采购、生产组装、质检等各个环节严格把控，力争做得更好，在我们“智本人”全员的努力下于本月顺利通过ISO9001质量管理体系认证。
            发文结尾处，再次感谢您的支持，我们将秉承客户第一、团队合作、拼搏实干、诚信敬业的核心价值观，为每一个信任和支持智本科技的客户提供更优质的服务。另外：小智提示您，我们可不只是生产企业、我们还是研发机构、更是方案解决商！欢迎各位有识之士来电洽谈合作，电话：010-83612390
            18911910456
            王先生。感谢您长期以来对我司的关注、陪伴和见证了我们的成长，小智给各位“衣食父母”汇报一下近期取得的成绩，截止发搞时，我们服务客户量已过10000，遍及全国各地。
            为了给客户提供性价比更高的产品，我们从产品的研发、原料采购、生产组装、质检等各个环节严格把控，力争做得更好，在我们“智本人”全员的努力下于本月顺利通过ISO9001质量管理体系认证。为了给客户提供性价比更高的产品，我们从产品的研发、原料采购、生产组装、质检等各个环节严格把控，力争做得更好，在我们“智本人”全员的努力下于本月顺利通过ISO9001质量管理体系认证。 -->
          </p>
          <div class="share_box">
            <p class="flex-cener-center">分享至：</p>
            <share :config="config"></share>
          </div>
          <div class="ta-c flex-cener-center margin-tp-60">
            <p class="flex-space-center margin-rt-60" v-if="info.prev">
              <span class="margin-rt-15">{{ info.prev.title }}</span>
              <el-button icon="el-icon-arrow-left" @click="bindPrevNext(info.prev.id)"
                >上一篇</el-button
              >
            </p>
            <p class="flex-space-center" v-if="info.next">
              <span class="margin-rt-15">{{ info.next.title }}</span>
              <el-button @click="bindPrevNext(info.next.id)"
                >下一篇 <i class="el-icon-arrow-right el-icon--right"></i
              ></el-button>
            </p>
          </div>
        </el-col>
        <el-col :span="4" class="moment-rt">
          <div class="page_title_name newtitle">
            <h3>新闻中心</h3>
            <span>NEWS CENTER</span>
          </div>
          <div class="hort">
            <span class="title">热门文章</span>
            <div class="hort_list">
              <div
                class="gray_bg_new"
                v-for="item in hortlist"
                :key="item.id"
                :style="{ backgroundImage: 'url(' + item.pic + ')' }"
              >
                <div class="hort_item" @click="bindPrevNext(item.id)">
                  <h6>{{ item.title }}</h6>
                  <p class="hort_desc line3">{{ item.desc }}</p>
                  <p>{{ item.create_time }} 中集智本</p>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-center">
            <img :src="require('@/assets/images/news/newsdetail.png')" class="news_image" />
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: "",
      value1: "",
      options: [],
      hortlist: [],
      info: {
        prev: {
          title: "",
        },
        next: {
          title: "",
        },
      },
      config: {
        // url: "", // 网址，默认使用 window.location.href
        source: "", // 来源（QQ空间会用到）, 默认读取head标签：<meta name="site" content="http://overtrue" />
        title: "", // 标题，默认读取 document.title 或者 <meta name="title" content="share.js" />
        description: "", // 描述, 默认读取head标签：<meta name="description" content="PHP弱类型的实现原理分析" />
        image: "", // 图片, 默认取网页中第一个img标签
        sites: ["qzone", "qq", "weibo", "wechat"], // 启用的站点
        wechatQrcodeTitle: "微信扫一扫：分享", // 微信二维码提示文字
        wechatQrcodeHelper: "<p>微信里点“发现”，扫一下</p><p>二维码便可将本文分享至朋友圈。</p>",
      },
    };
  },
  watch: {
    $route: {
      handler: function(route) {
        if (route.path == "/news/detail") {
          this.getNewDetail(route.query.id);
        }
      },
      immediate: true,
    },
  },
  activated() {
    window.scrollTo(0, 0);
    this.id = this.$route.query.id;
    // this.getNewDetail(this.$route.query.id);
  },
  methods: {
    getNewList(type_id) {
      this.$http({
        method: "get",
        url: "website_content_list",
        params: {
          limit: 2,
          page: 1,
          type: 3,
          type_id,
        },
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.hortlist = res.data.data;
        }
      });
    },
    bindPrevNext(id) {
      this.$router.push({
        path: "/news/detail",
        query: {
          id: id,
        },
      });
    },
    getNewDetail(id) {
      this.$http({
        method: "get",
        url: "website_content_detail",
        params: {
          id,
        },
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.info = res.data;
          this.config.title = res.data.title;
          this.config.description = res.data.desc;
          this.getNewList(res.data.type_id);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-row {
  display: flex;
}
::v-deep .el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  color: #ffffff;
}
.bgblue {
  background: #006acd;
  height: 350px;
  .toptitle {
    margin-left: 8%;
  }
  .page_location {
    margin-top: 30px;
    ::v-deep .el-breadcrumb {
      .el-breadcrumb__inner a,
      .el-breadcrumb__inner.is-link,
      .el-breadcrumb__inner {
        color: #ffffff;
      }
    }
  }
  .title {
    h6 {
      font-size: 42px;
      color: #ffffff !important;
      height: 68px;
      line-height: 68px;
    }
    .descspan {
      font-size: 14px;
      font-family: Source Han Sans CN-Normal, Source Han Sans CN;
      font-weight: 400;
      color: #93beff !important;
      margin-top: 10px;
    }
  }
}
.page_news_content {
  margin-top: 100px;
  background: #f2f2f2;
  .page_content {
    margin-top: -176px;
    z-index: 222;
  }
}
.moment-row {
  text-align: center;
  // padding-top: 30px;
  // padding-bottom: 72px;
  padding: 30px 30px 80px;
  margin-bottom: 30px;
  background: #f4f4f4;
  width: 68%;
  margin-left: 8%;
  // overflow: hidden;
  // float: right;
  .title {
    font-size: 42px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    height: 42px;
    line-height: 42px;
    margin-bottom: 30px;
  }
  .info {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 26px;
    text-align: left;
  }
}
.moment-rt {
  padding: 0px 15px 30px 15px;

  // background: #f2f2f2;
  .newtitle {
    color: #fff;
    h3 {
      color: #fff;
    }
    span {
      color: #fff;
    }
  }
  .hort {
    margin: 30px auto 5px;
    .title {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #fff;
      line-height: 46px;
      height: 46px;
    }
    .hort_list {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      h6 {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #fff;
        line-height: 24px;
        height: auto;
      }
      .gray_bg_new {
        cursor: pointer;
        margin-bottom: 15px;
        background-size: cover;

        // opacity: 0.5;
        border-radius: 0px 0px 0px 0px;
        color: #ffffff;
        line-height: 18px;
        .hort_item {
          background: rgba(0, 0, 0, 0.51);
          padding: 15px;
          //   background: url("../../assets/images/main/company_img.png") no-repeat;
        }
        .hort_desc {
          margin: 5px 0 10px;
        }
        // opacity: 1;
      }
    }
  }
  .news_image {
    width: 100%;
    height: auto;
    margin-top: 15px;
  }
}
</style>
